h3 {
  text-transform: uppercase;
}

.close-bug-report {
  margin-top: 20px;
}

.review-search-result a {
  color: #d71920;
  text-decoration: underline;
}

.review-search-result a:visited {
  color: #656d78;
}

.review-search-result a:hover {
  color: #434a54;
}

.review-search-result ol {
  list-style-type: none;
}

.localNav {
  background-color: white;
  width: 20.25%;
  padding: 5px 20px;
  z-index: 60;
  position: fixed;
  left: 0;
}

a .localNav {
  text-decoration: none;
  color: black;
}

.selectedNav {
  background-color: #999;
  font-weight: 600;
}

a .selectedNav {
  color: #112e4e;
}

.selectedNav:after {
  width: 0;
  height: 0;
  border-top: 15px inset transparent;
  border-bottom: 15px inset transparent;
  border-left: 12px solid #999;
  position: absolute;
  content: "";
  top: -1px;
  right: -11px;
  z-index: 90;
}

#all-reviews, #edit-cust, #report-settings, #all-sites, #cust-report-settings {
  top: 140px;
}

#my-reviews, #report-summary, #summary-nav, #all-work-orders, #cust-report-summary {
  top: 190px;
}

.bug-report {
  margin: 20px 0px;
  background-color: #e6e9ed;
  padding: 10px;
  width: 75%;
}

.bug-report div {
  margin: 10px 0px;
}

.work-order-block {
  margin: 10px 0px;
}

#report-kpi, #create-work-order, #cust-report-work-order {
  top: 240px;
}

#report-work-order, #create-user, #cust-report-equipment {
  top: 290px;
}

#report-equipment, #create-contract {
  top: 340px;
}

#report-parts, #all-contracts {
  top: 390px;
}

#report-reviews, #all-bug-reports {
  top: 440px;
}

#new-customer {
  top: 490px;
}

#all-invoices {
  top: 540px;
}

#all-invoices-vendor { 
  top: 190px;
}
#all-documents {
  top: 590px;
}

#review-work-orders {
  top: 640px;
}

#review-notifications {
  position: fixed;
  left: 100px;
  top: 380px;
  z-index: 99;
  font-size: 10px;
  background-color: red;
  border-radius: 200px;
  padding-top: 7px;
  color: white;
  font-weight: bold;
  height: 27px;
  width: 27px;
  text-align: center;
}

ul#pagination {
  list-style-type: none;
  font-size: 18px;
  margin: 60px 0px 20px 0px;
  padding: 0;
}

ul#pagination li {
  display: inline;
  padding: 10px 12px;
  font-weight: 500;
  background: #e6e9ed;
  border: 1px solid #ccd1d9;
}

ul#pagination li:hover {
  background: #112e4e;
}

ul#pagination a li:hover {
  color: white;
}

ul#pagination a li {
  color: #656d78;
}

ul#pagination li.active {
  background: #112e4e !important;
}

ul#pagination a li.active {
  color: white;
}

#prev-page {
  border-radius: 6px 0px 0px 6px;
  background: #112e4e !important;
}

#prev-page, #next-page {
  color: white !important;
}

#next-page {
  border-radius: 0px 6px 6px 0px;
  background: #112e4e !important;
}


ul#pagination a li {
  text-decoration: none;
}
