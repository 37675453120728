.modal-content {
  background: #87A3c1;
  width: 50%;
  margin: 5% auto;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #888;
  z-index: 91;
  max-height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modal-content::-webkit-scrollbar {
  display: none;
}

.modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 90;
}

.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.splitButtons {
  display: inline-block;
  width: 50%;
  margin-top: 40px;
}

.thirdButtons {
  display: inline-block;
  width: 25%;
  margin-top: 40px;
}

.delete-modal {
  margin: 20% auto !important;
}

.close:hover, .close:focus {
  color: black;
  text-decoration: none;
}

.modal-body th, .modal-body td {
  width: 155px
}

.modal-content .fieldContainer {
  margin: 0px !important;
}

.modal-content .field {
  background-color: #87A3c1;
}

.modal-footer button {
}

.modal-content td {
  padding: 5px 15px;
  text-align: center;
}
