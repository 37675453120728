div#header {
	width: 100%;
	border-style: solid;
	border-width: 7px;
	border-color: white;
	margin-top: 30px;
	padding: 10px 10px 10px 0px;
  z-index: 89;
  background: #112e4e;
	position: fixed;
}

#bug-report {
  float: right;
  position: inline;
  padding-right: 40px;
  padding-top: 10px;
}

#bug-report a {
  color: white;
}

#bug-report img {
  height: 17px;
  padding-right: 10px;
}

#bug-report a:hover {
  color: #656d78;
}

textarea#bug-description {
  width: 100% !important;
}

.globalNav {
	margin-right: 20px;
	padding-right: 20px;
	padding-bottom: 12px;
	padding-top: 23px;
	padding-left: 25px;
	position: relative;
	z-index: 89;
}

#backButton {
	margin-left: -19px;
	padding-left: 39px;
	z-index: 80;
}

#settingsNav {
	margin-left: -20px;
	padding-left: 40px;
	z-index: 79;
}

#signOutNav {
	margin-left: -20px;
	padding-left: 41px;
	z-index: 78;
}

a .globalNav {
	text-decoration: none;
	text-transform: uppercase;
	color: white;
}

.globalNav:hover {
	background: #656d78;
}

.globalNav:hover .globalNavStyling:after {
	border-left-color: #656d78;
}

.globalNav a:visited {
	color: white;
}

.barrier:after {
	width: 0;
	height: 0;
	border-top: 30px inset transparent;
	border-bottom: 30px inset transparent;
	border-left: 25px solid white;
	position: absolute;
	content: "";
	top: -5px;
	right: -25px;
	z-index: 95;
}

.globalNavStyling:after {
	width: 0;
	height: 0;
	border-top: 26px inset transparent;
	border-bottom: 26px inset transparent;
	border-left: 21px solid #112e4e;
	position: absolute;
	content: "";
	top: -1px;
	right: -21px;
	z-index: 96;
}

#globalSearchResults {
	position: absolute;
	top: 44px;
	right: 10px;
	width: 300px;
	background-color: white;
	border: 1px solid #ccd1d9;
  border-radius: 4px;
  box-shadow: 0px 4px 5px 1px #DDD;
}

#globalSearchWrapper {
  position: inline;
  float: right;
}

#localSearchResults, #custSearchResults, #vendorSearchResults {
	width: 300px;
	background-color: white;
	border: 1px solid #ccd1d9;
	border-radius: 4px;
	box-shadow: 0px 4px 5px 1px #DDD;
}

#globalSearchResults div .searchResult {
	padding: 5px;
	border-bottom: 1px solid #ccd1d9;
	font-weight: bold;
}

#localSearchResults div .localSearchResults {
	padding: 5px;
	border-bottom: 1px solid #ccd1d9;
	font-weight: bold;
}

#globalSearchSelector {
	display: inline;
	float: right;
}

#globalSearchSelector div {
	margin: 0px;
	width: 250px;
}
