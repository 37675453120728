.customer-info {
  margin: 20px 0px;
}

.accordion-form .field {
  background-color: transparent !important;
}

.amount {
  margin-left: 40px;
}

.equip-block {
  margin: 30px 0px;
  width: 33%;
  float: left;
  padding-left: 5%;
}

.row:after {
  content: '';
  display: table;
  clear: both;
}

.workOrderTable {
  width: 100%;
}

.workOrderTable td {
  padding: 10px;
}
