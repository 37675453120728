.listed-document {
  background-color: #e6e9ed;
  padding: 10px;
  width: 75%;
}

.fieldContainer {
	margin: 20px 0px;
}

.summaryCostChart {
	margin-top: 50px;
}

.fieldContainer a:hover, .drilldown a:hover {
	color: #d71920;
}

.lineChart {
	width: 80%;
	font-family: 'Arial' !important;
}

.select-dropdown-label {
  padding-bottom: 20px;
}

button {
  border-radius: 4px;
  font-size: 14px;
	text-transform: uppercase;
  font-weight: 400;
  border-width: 0px;
	padding: 10px 35px;
  transition: 0.3s background-color ease-in-out, 0.3s color ease-in-out;
	background-color: #112e4e;
	color: white;
	margin-left: 70%;
}

button:disabled {
  background-color: grey;
  color: #505050;
}

button:disabled:hover {
  background-color: grey;
}

button.destroy {
  background-color: darkred !important;
}

button.destroy:hover {
  background: #ED9EA1 !important;
}

td button{
	margin-left: 0% !important;
}

table.contractEquipments td {
  width: 180px !important;
  padding-right: 30px;
}

table#service-line-items-table {
  width: 90% !important;
  border: 1px solid black;
}

table#service-line-items-table td {
  border: 1px solid black;
}

table.contractEquipments div.field {
  width: 180px !important;
}

table.contractEquipments input.date-time-date {
  width: 100% !important;
}

button:hover {
  background: #87A3c1;
}

button.sign-in {
	padding: 10px 150px;
	margin-left: 0% !important;
}

button#update-review-user {
	margin-top: -10px !important;
}

.centered {
	display: flex;
	align-items: center;
	justify-content: center;
}

.fieldRow {
  height: 70px;
}

label {
	font-weight: 700;
	font-size: 14px;
}

.text-field.error, .currency.error, .date-time-date.error {
	border-color: #d71920;
}

textarea.error {
	border-color: #d71920;
}

.table-field {
	margin-bottom: 10px !important;
}

.field-error {
	color: #d71920;
	font-weight: 500;
	display: inline;
}

#globalSearchContainer {
  display: inline;
	float: right;
}

#globalSearchContainer input, #localSearchContainer input, .localSearchContainer input{
	border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #282828;
  border-style: none;
	width: 300px;
  padding: 7px;
	display: inline;
}

#localSearchContainer input, .localSearchContainer input{
	border-style: solid;
}

#globalSearchContainer button:hover {
	background-color: #e6e9ed;
	color: #112e4e;
}

.field {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.3);
}

.field input {
  position: relative;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  color: #282828;
  outline: none;
	border-style: solid;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
    0.1s padding ease-in-out;
  -webkit-appearance: none;
	width: 75%;
	margin-top: 10px;
	margin-bottom: 30px;
	padding: 7px;
}

table .field .currency {
	width: 100px !important;
}

table .field {
	width: 130px !important;
}

.field .currency {
	width: 75% !important;
}

.field .currency input {
	border-radius: 0px 4px 4px 0px;
}

.currency-sign {
	background: #AAb2bd;
	color: white;
	padding-top: 10px;
	padding-bottom: 8px;
	padding-right: 9px;
	padding-left: 9px;
	border-radius: 4px 0px 0px 4px;
}

td .field {
	width: 100%;
}


.title-toggle {
	background-color: #112e4e;
	color: white;
	border-radius: 4px;
	padding: 0.5px;
	margin-top: 15px;
	width: 90%;
}

.hidden {
	display: none;
}

.show {
	display: inline;
	padding-left: 5%;
}

.display-list {
}

.visible {
	margin-bottom: 10px;
	width: 90%;
	background-color: #e6e9ed;
	padding: 15px;
	border-radius: 4px;
}

#open-note-space {
	margin-top: 20px;
}

.note-content {
	padding-bottom: 10px;
	border-bottom: 1px solid #AAB2BD;
}

.fieldLabel {
	margin-bottom: 5px;
}

.inline {
  display: inline;
  padding-right: 10px;
}

textarea {
	border-radius: 5px;
	font-family: 'Arial';
	padding: 5px;
	width: 75%;
	height: 200px;
}

select {
	font-family: 'Arial';
	width: 75%;
	border-radius: 4px;
	padding: 5px;
}

td select {
	width: 100%;
}

#reviewCommentArea {
	margin: 10px 0px;
}

.log-item {
	padding-bottom: 10px;
	border-bottom: 1px solid #AAB2BD;
}

.date-time-date {
	font-family: 'Arial';
	width: 20% !important;
}

.timeContainer {
	border: 1px solid #ccd1d9;
	border-radius: 4px;
	width: 20%;
	display: inline;
	padding: 10px;
	margin-left: 40px;
}

.timeContainer input {
	border-style: none;
	width: 35px;
}

.timeContainer input[type=number]::-webkit-inner-spin-button,
.timeContainer input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

.timeContainer input[type=number] {
	-moz-appearance: textfield;
}
