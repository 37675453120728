.alert {
  color: white;
  border-radius: 5px;
  position: fixed;
  top: 150px;
  right: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  z-index: 1111;
}

.alert p {
  margin: 0;
}

.alert-error {
  background: #d71920;
}

.alert-success {
  background: #539708;
}

.close {
  color: white;
  cursor: pointer;
  margin-right: 10px;
}

