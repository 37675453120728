#leftSidebar {
	width: 20%;
	height: 100%;
	z-index: 50;
	top: 0;
	left: 0;
	background-color: #112e4e;
	overflow-x: hidden;
	padding-top: 120px;
	position: fixed;
}

#mainContainer {
	margin-left: 25%;
  	padding-top: 100px;
}

#topHider {
	height: 90px;
	width: 100%;
	position: fixed;
	z-index: 49;
	background-color: white;
}

#logo {
	width: 100%;
	position: absolute;
	bottom: 5%;
}

#logo img {
	width: 80%;
	display: block;
	margin: 0px auto;
}
