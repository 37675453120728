body {
  margin: 0;
  font-family: 'Arial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	padding-bottom: 50px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div#wrapper {
	padding: 25px;
}

* {
	box-sizing: border-box;
}

body {
}

.spacer {
	margin-top: 80px;
}

h4 {
	text-align: center;
}

a {
	color: #656d78;
	text-decoration: none;
}

a:hover {
	color: #112e4e;
}
