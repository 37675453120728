#wo-table {
	border: 10;
}

.wo-desc {
	width: 300px;
}

tr.wo-row td{
	padding: 15px;
}

tr.wo-row {
	border-bottom: 1px solid black;
}

td {
	text-align: center;
}

td.financial {
	text-align: right;
}

#total-table {
	position: absolute;
	table-layout: fixed;
	top: 120px;
	left: 40%;
}

#summary-table {
	table-layout: fixed;
}

#total-table th, td {
	width: 90px;
}

td.summary-special-header {
	text-align: right;
	font-weight: bold;
	border: 0px;
}

.summary-header {
	width: 100%;
}

.summary-header div:first-child {
	width: 50%;
}

.summary-header div {
	width: 25%;
	float: left;
	padding-bottom: 30px;
}

.inaccurate-hours {
	color: red;
	font-weight: bold;
}

.inaccurate-cell {
	background-color: #ffb3b3
}

.info-box {
	background-color: #e0f2f1; /* Light blue background color */
	border: 1px solid #80cbc4; /* Border color */
	padding: 10px; /* Padding around the content */
	border-radius: 5px; /* Rounded corners */
	display: inline-block;
	position: relative;
	width: 45%;
	height: 100%;
	bottom: 100px;
	cursor: default;
}

.info-box ul {
	margin: 5px; /* Remove default paragraph margin */
	padding-left: 20px; /* Add space for bullet points */
	list-style-type: disc; /* Use bullet points */
}

.info-box li {
	margin: 5px 0; /* Add margin between list items */
}

.info-box-header {
	font-weight: bold;
	margin-bottom: 10px;
	font-size: 22px;
	text-align: center;
}

.info-icon {
	color: red;
	font-size: 24px;
	margin-right: 5px;
}

.tooltip-container {
	position: relative;
}

.tooltip-container .tooltip {
	position: absolute;
	top: -55px; /* Adjust as needed */
	left: 50%;
	width: 250px; /* Adjust width as needed */
	transform: translateX(-50%);
	background-color: #e0f2f1; /* Light blue background color */
	border: 1px solid #80cbc4; /* Border color */
	color: rgb(0, 0, 0); /* Text color */
	padding: 8px;
	border-radius: 20px; /* Adjust for rounded corners */
	visibility: hidden;
	z-index: 1;
}

.tooltip-container:hover .tooltip {
	visibility: visible;
}

.important {
	color: red;
}
